import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";


const PercentageInput = ({ value, setValue }) => {
    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (inputValue === "" || (inputValue >= 0 && inputValue <= 100)) {
            setValue(inputValue);
        }
    };

    const handleBlur = () => {
        if (value === "" || value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };
    useEffect(() => {
        const inputField = document.getElementById("focus-input");
        if (inputField) {
            inputField.focus();
        }
    }, []);

    return (
        <Form.Control
            type="text"
            id={"focus-input"}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
};

export default PercentageInput;