import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form'
import {useDispatch, useSelector} from "react-redux";
import PercentageInput from "../form/PercentageInput";
import {setCountLKT, setLKTValue} from "../../../features/DamageBill/bill.slice";

const LKTModal = ({
                                show, onClose
                            }: { show: boolean, onClose: () => void }) => {
    const { materialWage, typeLaborArray} = useSelector(state => state.damageBill)
    const [inputLKT, setInputLKT]= useState(100 - parseInt(materialWage?.wage?.amount,10));
    const dispatch= useDispatch();

    return (<Modal show={show} onHide={() => {
        onClose();
    }} className={'modal-xs'} style={{width: '15%', positionArea: 'center'}}>
        <Modal.Header closeButton>
            <Modal.Title style={{fontSize: '1.2rem'}}>Arbeitskostenwert festlegen</Modal.Title>
        </Modal.Header>
        <Modal.Body >

            { typeLaborArray?.length > 0 ? <div>
                <Form.Group controlId="formBasicLkt">
                    <div style={{display: 'flex', flexDirection: 'column', gap: '0rem'}}>
                        <span style={{
                            fontSize: '0.8rem'
                        }}>* Der Wert der Arbeitskosten sollte in % angegeben werden</span>
                    </div>

                    <PercentageInput value={inputLKT} setValue={setInputLKT} />

                </Form.Group>

                <div style={{
                    gap: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    <Button
                        variant="primary"
                        onClick={()=>{typeLaborArray.length!==0 && dispatch(setLKTValue(inputLKT)) && dispatch(setCountLKT()) && onClose()}}
                    >Anwenden</Button>
                </div>
            </div> : <div>Sie können den LKT-Wert nicht ändern, da in der Tabelle mit Rechnungsposten keine Löhne ausgewählt wurden.</div>}
        </Modal.Body>
    </Modal>)
}


export default LKTModal;