import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form'
import {useDispatch, useSelector} from "react-redux";
import PercentageInput from "../form/PercentageInput";
import {setCountMKT, setMKTValue} from "../../../features/DamageBill/bill.slice";

const MKTModal = ({
                      show, onClose
                  }: { show: boolean, onClose: () => void }) => {
    const { materialWage, typePartsArray} = useSelector(state => state.damageBill)
    const [inputMKT, setInputMKT]= useState(100 - parseInt(materialWage?.material?.amount,10));
    const dispatch= useDispatch();

    return (<Modal show={show} onHide={() => {
        onClose();
    }} className={'modal-xs'} style={{width: '15%', positionArea: 'center'}}>
        <Modal.Header closeButton>
            <Modal.Title style={{fontSize: '1.2rem'}}>Materialkostenanteil festlegen</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        {typePartsArray?.length>0 ?

            <div>

                <Form.Group controlId="formBasicLkt">
                    <div style={{display: 'flex', flexDirection: 'column', gap: '0rem'}}>
                        <span style={{
                            fontSize: '0.8rem'
                        }}>* Der Materialkostenwert sollte in % angegeben werden</span>
                    </div>
                    <PercentageInput value={inputMKT} setValue={setInputMKT} />

                </Form.Group>

                <div style={{
                    gap: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    <Button
                        variant="primary"
                        onClick={()=>{typePartsArray.length!==0 && dispatch(setMKTValue(inputMKT)) && dispatch(setCountMKT()) && onClose()}}
                    >Anwenden</Button>
                </div>
            </div>
         : <div>Sie können den MKT-Wert nicht ändern, da keine Teile in der Tabelle mit Rechnungsposten ausgewählt wurden.</div>}
        </Modal.Body>
    </Modal>)
}


export default MKTModal;