import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {InitialStateType} from "../../Contexts/Auth/context";
import {authLogin, authLogout, checkForUser} from "../../services/API/auth.service";


/**
 * Used in initial state or for resetting a user
 * @type {{uid: null, username: string, token: string}}
 */
const emptyLoggedUser = {
    username: '',
    token: '',
    uid: null,
    hasMfa: false
}

/**
 * Used in authSlice as initial state of the reducer
 * @type {{loggedUser: {uid: null, username: string, token: string}, loadingInitial: boolean, error: string, loading: boolean}}
 */
export const initialState: InitialStateType = {
    loggedUser: {
        ...emptyLoggedUser
    },
    error: '',
    loading: false,
    loadingInitial: true // this is used in order to display nothing until we know if user is logged in or not
}


/**
 * Handles Login and stores user in Redux Store
 * @type {AsyncThunk<unknown, void, {}>}
 */
export const login = createAsyncThunk(
    "auth/login",
    async ({username, password, mfaCode}, thunkAPI) => {
        try {
            const {data} = await authLogin(username, password, mfaCode);
            if (data.code === 407) return {loggedUser: {username, hasMfa: true}};
            else return {
                loggedUser: {
                    username,
                    hasMfa: false,
                    token: data.access_token,
                    uid: data.user_id,
                    ...data
                }
            };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({
                error: message
            });
        }
    }
);


/**
 * Check if user session is active and stores user in Redux Store
 * @type {AsyncThunk<unknown, void, {}>}
 */
export const checkLoggedUser = createAsyncThunk(
    "auth/check",
    async (_, thunkAPI) => {
        try {
            const {data} = await checkForUser();
            return {
                loggedUser: {
                    username: '',
                    token: data.access_token,
                    uid: data.user_id,
                    ...data
                },
            };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            // thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

/**
 * Handles Logout
 * @type {AsyncThunk<unknown, void, {}>}
 */
export const logout = createAsyncThunk(
    "auth/logout", async (_, thunkAPI) => {
        try {
            await authLogout();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


/**
 * authSlice manages the logic of authentication for this application
 * It handles login, logout and checking if user's session is active
 * @type {Slice<InitialStateType, {cleanError: reducers.cleanError}, string>}
 */
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        cleanError: (state) => {
            state.error = ''
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.loggedUser = action.payload.loggedUser;
        },
        [login.rejected]: (state, action) => { // reset user information
            state.loggedUser = {
                ...emptyLoggedUser
            };
            state.error = action.payload.error
        },
        [checkLoggedUser.fulfilled]: (state, action) => {
            state.loggedUser = action.payload.loggedUser;
            state.loadingInitial = false
        },
        [checkLoggedUser.rejected]: (state) => {
            state.loggedUser = {
                ...emptyLoggedUser
            };
            state.loadingInitial = false
        },
        [logout.fulfilled]: () => {
            return {
                ...initialState,
                loadingInitial: false
            }
        }
    }
})

const {reducer} = authSlice;
export const {cleanError} = authSlice.actions;
export default reducer;
