import {InputNumber} from "./InputNumber";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {
    calculateItemTotal,
    calculateItemTotalDeduction,
    calculateQuantityPriceZero
} from "../../../utils/damageItemsCalculations";
import {ItemTypeSelect} from "./components/ItemTypeSelect";
import {InputText} from "./components/InputText";
import {DeductionCodeAutocomplete} from "./DeductionCodeAutocomplete";
import {useDispatch, useSelector} from "react-redux";
import plusIcon from '../../../assets/images/plusIcon.svg';
import deleteIcon from '../../../assets/images/deleteIcon.svg'
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import TextareaAutosize from "react-textarea-autosize";
import {setLKTValue, setMKTValue} from "../../../features/DamageBill/bill.slice";

const fmt = new Intl.NumberFormat(navigator.languages[0], {minimumFractionDigits: 2, maximumFractionDigits: 2})

export function numberFormat(number) {
    return fmt.format(number);
}


function FormBillItemsRow({
                              item,
                              index,
                              stateChanger,
                              setFocusedId,
                              focusedId,
                              getNextFocusableField,
                              deductionCodeList,
                              addNewItem,
                              billItems,
                              handleContextMenu,
                              selectValueContext,
                              toggleCell,
                              selectedCells,
                              setSelectValueContext,
                              sparePartsForLoadedBill,
                              showConvertedValue,
                              hideConvertedValue
                          }) {
    const {isReadOnly, damage, clickCountMKT, clickCountLKT, typePartsArray, typeLaborArray, mktValue, lktValue, materialWage} = useSelector(state => state.damageBill)
    const [state, setState] = useState({
        item
    });
    const [totalAfterAllCalculation, setTotalAfterAllCalculation] = useState(0);
    const [valueInLabel, setValueInLabel]= useState();
    const dispatch= useDispatch();
    // Including state.item creates an infinite rendering loop
    // This is happening because we get item as props we update the state internally
    // and then we push that change with stateChanger and that triggers the props to change and so on ....
    // TODO: Find a way to update the stage in only once place instead of trying to sync 2 different sources for the same component
    const updateItem = useCallback((field, value) => {
        setState(prevState => {
            let tmpItem = {
                ...prevState.item
            };
            tmpItem[field] = value;
            if (field === 'price') {
                tmpItem['total_for_paying'] = parseFloat(value * tmpItem['quantity']).toFixed(2);
                tmpItem['total_for_paying'] = calculateItemTotal(tmpItem).toFixed(2);
            }
            if (field === 'quantity') {
                tmpItem['total_for_paying'] = parseFloat(value * tmpItem['price']).toFixed(2);
                tmpItem['total_for_paying'] = calculateItemTotal(tmpItem).toFixed(2);
            }
            if (field === 'discount') {
                tmpItem['total_for_paying'] = parseFloat(tmpItem['quantity'] * tmpItem['price']).toFixed(2);
                tmpItem['total_for_paying'] = calculateItemTotal(tmpItem).toFixed(2);
            }
            if (field === 'item_type') {
                if (value === 1) {
                    if (!!lktValue) {
                        tmpItem['deduction_in_percent'] = parseFloat(lktValue).toFixed(2);
                    } else{
                        tmpItem['deduction_in_percent'] = (100 - parseFloat(materialWage?.wage?.amount)).toFixed(2);
                        dispatch(setLKTValue(100 - parseFloat(materialWage?.wage?.amount)));
                    }
                }
                if (value === 2) {
                    if (!!mktValue) {
                        tmpItem['deduction_in_percent'] = parseFloat(mktValue).toFixed(2);
                    } else{
                        tmpItem['deduction_in_percent'] = (100 - parseFloat(materialWage?.material?.amount)).toFixed(2);
                        dispatch(setMKTValue(100 - parseFloat(materialWage?.material?.amount)));
                    }
                 }
            }
            return {
                item: tmpItem
            }
        })
    }, [lktValue, materialWage?.wage?.amount, dispatch, mktValue, materialWage?.material?.amount]);


    useEffect(() => {
        let tmpTotalDeduction = calculateItemTotalDeduction(state.item, typeLaborArray, typePartsArray, mktValue, lktValue, clickCountLKT, clickCountMKT);
        setTotalAfterAllCalculation(state.item.total_for_paying - tmpTotalDeduction);
    }, [state.item, stateChanger, index, clickCountLKT, clickCountMKT, state.item.deduction_in_percent]);


    function handleDeleteItem() {
        stateChanger(index, state.item, 'delete');
    }

    const conditionUpdate = selectedCells.includes(index + 1);
    //selection and update values main functionalities
    useEffect(() => {
        if (!selectValueContext || !selectValueContext.value) {
            return;
        }
        let tmpItem = state.item;
            setValueInLabel(selectValueContext?.id);
                setState(prevState => {
                    let tmpItem = prevState.item;
                    tmpItem['spare_part_id'] = selectValueContext?.value;
                    return {
                        item: tmpItem
                    }
                })
            stateChanger(index, {
                ...tmpItem
            }, 'spare_part_id')
            setSelectValueContext(null);

    }, [conditionUpdate])

    function handleClicked(event) {
        let tmpItem = state.item;
        tmpItem['is_taxable_for_second_tax'] = event.target.checked;
        setState({
            item: tmpItem
        })
        stateChanger(index, {
            ...tmpItem
        }, 'totals')
    }

    useEffect(() => {
        if(clickCountMKT>0 && typePartsArray.includes(index)) {
            let tmpItem = state.item;
            tmpItem['deduction_in_percent'] = parseFloat(mktValue).toFixed(2);
            setState(prevState => {
                let tmpItem = prevState.item;
                tmpItem['deduction_in_percent'] = parseFloat(mktValue).toFixed(2);
                return {
                    item: tmpItem
                }
            })
            stateChanger(index, {
                ...tmpItem
            }, 'deduction_in_percent')
        }

    }, [clickCountMKT]);

    useEffect(() => {
        if(clickCountLKT>0 && typeLaborArray.includes(index)) {
            let tmpItem = state.item;
            tmpItem['deduction_in_percent'] = parseFloat(lktValue).toFixed(2);
            setState(prevState => {
                let tmpItem = prevState.item;
                tmpItem['deduction_in_percent'] = parseFloat(lktValue).toFixed(2);
                return {
                    item: tmpItem
                }
            })
            stateChanger(index, {
                ...tmpItem
            }, 'deduction_in_percent')
        }

    }, [clickCountLKT]);

    useEffect(() => {
        stateChanger(index, {
            ...state.item
        }, 'totals')
    }, [state.item, index]);

    useEffect(() => {

        if (!state.item || !sparePartsForLoadedBill?.records || !sparePartsForLoadedBill) {
            return;
        }
        let sparePartsPairsS = sparePartsForLoadedBill?.records.map((item, index) => ({
            key: item?.teilid,
            value: index + 1

        }))

        if (sparePartsPairsS?.some(itemI => itemI.key === state?.item?.spare_part_id)) {

            const pair = sparePartsPairsS.find(item => item.key === state?.item?.spare_part_id)
            setValueInLabel(pair.value);

        }

    }, [sparePartsForLoadedBill])
    const windowDimension = useWindowDimensions();
    useEffect(() => {
        let elements = document.querySelectorAll(`.input_1`);
        let wrapper = document.querySelectorAll(`.spare_part_wrapper`);
        elements.forEach((item, index) => {
            wrapper[index].style.height = item.style.height;
        });

    }, [windowDimension])


    return (
        <tr className={"Table-Row someClass"} id={"damage-bill-items-" + index + 1}>
            <td className={`hash ${!isReadOnly &&  damage?.damage_number && selectedCells.includes(index + 1) ? 'hash-background' : ''}`}>
                {index + 1}
                <input type="hidden" name={"items[" + index + "][id]"} value={state.item.id} disabled={isReadOnly} className={'input'}/>
            </td>
            <td className={"Type"}>
               <ItemTypeSelect
                        dataTableItem={[index, 0]}
                        setFocusedId={setFocusedId}
                        name={"items[" + index + "][item_type]"}
                        getNextFocusableField={getNextFocusableField}
                        field="item_type"
                        stateChanger={updateItem}
                        value={state.item.item_type}
                        className={'form-control'}/>
            </td>
            <td className={"Code text-last"} style={{width: state.item.item_code ? `${state.item.item_code.length+3}ch` : '60px'}}>
                <span className={'input-wrap'}>
                    <span className={'width-machine'} aria-hidden={true}>{state.item.item_code}</span>
                    <InputText name={"items[" + index + "][item_code]"}
                               dataTableItem={[index, 1]}
                               setFocusedId={setFocusedId}
                               stateChanger={updateItem}
                               getNextFocusableField={getNextFocusableField}
                               field="item_code"
                               value={state.item.item_code}
                               className={"form-control input"}/>
                </span>
            </td>
            <td className={`Spare-part-value ${!isReadOnly && damage?.damage_number && selectedCells.includes(index + 1) ? 'selected-cells-bauteil' : ''}`}
                onContextMenu={handleContextMenu}
                onClick={() => {
                    toggleCell(index + 1);
                }}
            >
                <label htmlFor="" className={'spare_part_label'} style={{position: 'relative', display:"flex", flexDirection: 'row-reverse', alignItems: 'center'}}>
                    <div style={{position: 'absolute', right: '1ch'}}>
                        {valueInLabel}
                    </div>
                    <InputText
                        name={"items[" + index + "][spare_part_id]"}
                        dataTableItem={[index, 2]}
                        setFocusedId={setFocusedId}
                        stateChanger={updateItem}
                        getNextFocusableField={getNextFocusableField}
                        field="spare_part_id"
                        value={state?.item?.spare_part_id}
                        className={`form-control input`}
                        style={{color: 'white', textIndent: '-9999px'}}
                    />
                </label>
            </td>
            <td className={"Spare-part spare_part_wrapper"}>
                <span className={'input-wrap_1'}>
            <span className={'width-machine_1'} aria-hidden={true}>{state.item.description}</span>
                 <TextareaAutosize
                     name={"items[" + index + "][description]"}
                     data-table-item={`${[index, 3][0]}${[index, 3][1]}`}
                     value={state.item.description}
                     disabled={isReadOnly}
                     className={"form-control input_1"}
                     onKeyDown={(e) => {
                         if (e.key === 'Enter') {
                             e.preventDefault()
                             setFocusedId(getNextFocusableField([index, 3]))
                         }
                     }}
                     onChange={(e) => {
                         updateItem('description', e.target.value);
                         let elements = document.querySelectorAll(`.input_1`);
                         let wrapper = document.querySelectorAll(`.spare_part_wrapper`);
                         elements.forEach((item, index) => {
                             wrapper[index].style.height = item.style.height;
                         });
                     }}
                     style={{resize: 'none', overflow: 'hidden'}}
                 />
            </span>
            </td>
            <td className={"Quantity text-last"} style={{width: state.item.quantity ? `${numberFormat(state.item.quantity).length+2}ch` : '60px'}}>
                <span className={'input-wrap'}>
                    <span className={'width-machine'} aria-hidden={true}>{state.item.quantity}</span>
                    <InputNumber name={"items[" + index + "][quantity]"}
                             setFocusedId={setFocusedId}
                             type={'quantity'}
                             dataTableItem={[index, 4]}
                             getNextFocusableField={getNextFocusableField}
                             stateChanger={updateItem}
                             field="quantity"
                             initValue={state.item.quantity}
                             className={'form-control input'}/>
                </span>
            </td>
            <td className={"Price text-last"} style={{width: state.item.price ? `${numberFormat(state.item.price).length+2}ch` : '60px'}} onMouseOver={(e) => showConvertedValue(e, state.item.price)}
                onMouseOut={hideConvertedValue}>
                <span className={'input-wrap'}>
                    <span className={'width-machine'} aria-hidden={true}>{state.item.price}</span>
                    <InputNumber name={"items[" + index + "][price]"}
                             setFocusedId={setFocusedId}
                             type={'price'}
                             dataTableItem={[index, 5]}
                             getNextFocusableField={getNextFocusableField}
                             stateChanger={updateItem}
                             initValue={state.item.price} step="0.01"
                             field="price"
                             class="form-control input"/>
                    </span>
            </td>
            <td className={"Discount text-last"} style={{width: state.item.discount ? `${state.item.discount.length+2}ch` : '68px'}}>
                <span className={'input-wrap'}>
                    <span className={'width-machine'} aria-hidden={true}>{state.item.discount}</span>
                    <InputNumber name={"items[" + index + "][discount]"}
                             setFocusedId={setFocusedId}
                             type={'discount'}
                             getNextFocusableField={getNextFocusableField}
                             dataTableItem={[index, 6]}
                             stateChanger={updateItem}
                             initValue={state.item.discount} step="0.01"
                             field="discount"
                             className="form-control input"/>
                </span>
            </td>
            <td className="Total-Label-Table align-text-right text-last" style={{width: state.item.total_for_paying ? `${numberFormat(state.item.total_for_paying).length+2}ch` : '100px'}} onMouseOver={(e) => showConvertedValue(e, state.item.total_for_paying)}
                onMouseOut={hideConvertedValue}>
                <span className={'input-wrap'}>
                    <span className={'width-machine'} aria-hidden={true}>{state.item.total_for_paying === null ? calculateQuantityPriceZero(state.item) : state.item.total_for_paying}</span>
                    <input name={"items[" + index + "][total_for_paying]"}
                       className="form-control input"
                       value={state.item.total_for_paying === null ? calculateQuantityPriceZero(state.item) : state.item.total_for_paying}
                       type={"number"}
                       onChange={(e) => {
                           updateItem('total_for_paying', e.target.value);
                       }}
                       data-table-item={`${[index, 7][0]}${[index, 7][1]}`}
                       step={"0.01"}
                       onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                               e.preventDefault();
                               setFocusedId(getNextFocusableField([index, 7]))
                           }
                       }}
                       disabled={isReadOnly}
                       onBlur={() => updateItem('total_for_paying', parseFloat(state.item.total_for_paying ? state.item.total_for_paying : 0).toFixed(2))}/>
                </span>
            </td>
            <td className={"Paying-code text-last paying_code_wrapper"} style={{width: state.item.deduction_code ? `${`${state.item.deduction_code?.code} - ${state.item.deduction_code?.name}`.length+2}ch` : '11ch'}}>
                {/*<InputNumber name={"items[" + index + "][paying_code]"}*/}
                {/*             setFocusedId={setFocusedId}*/}
                {/*             getNextFocusableField={getNextFocusableField}*/}
                {/*             stateChanger={updateItem}*/}
                {/*             type={'deduction'}*/}
                {/*             dataTableItem={[index, 6]}*/}
                {/*             initValue={state.item.paying_code} step="1"*/}
                {/*             field="paying_code"*/}
                {/*             class="form-control"*/}
                {/*/>*/}
                <span className={'input-wrap_2'}>
                    <span className={'width-machine_2'} aria-hidden={true}>{state.item.deduction_code ? `${state.item.deduction_code?.code} - ${state.item.deduction_code?.name}` : ``}</span>
                    <DeductionCodeAutocomplete name={"items[" + index + "][paying_code]"}
                                           focusedId={focusedId}
                                           setFocusedId={setFocusedId}
                                           stateChanger={updateItem}
                                           field="paying_code"
                                           getNextFocusableField={getNextFocusableField}
                                           dataTableItem={[index, 8]}
                                           deductionCode={state.item.deduction_code}
                                           autocompleteData={deductionCodeList}
                                           index={index}
                                           billItems={billItems}
                />
                </span>
            </td>
            <td className={"Paying-code discount_width text-last"} style={{width: state.item.deduction_in_percent ? `${numberFormat(state.item.deduction_in_percent).length+2}ch` : '60px'}}>
                  <span className={'input-wrap'}>
                    <span className={'width-machine'} aria-hidden={true}>{numberFormat(state.item.deduction_in_percent)}</span>
                      <input type="number"
                             onKeyDown={(e) => {
                                 if (e.key === 'Enter') {
                                     e.preventDefault()
                                     setFocusedId(getNextFocusableField([index, 9]))
                                 }
                             }}
                             data-table-item={`${[index, 9][0]}${[index, 9][1]}`}
                             name={"items[" + index + "][deduction_in_percent]"}
                             value={state.item.deduction_in_percent}
                             step="0.01"
                             className="form-control"
                             onChange={(e) => {
                                 updateItem('deduction_in_percent', e.target.value);
                             }}
                             onBlur={() => updateItem('deduction_in_percent', parseFloat(state.item.deduction_in_percent ? state.item.deduction_in_percent : state.item.item_type === 1 ? materialWage?.wage.amount : materialWage?.material.amount).toFixed(2))}
                             disabled={isReadOnly}
                      />
                  </span>
            </td>
            <td className={"Total-for-payment align-text-right px-2 text-last"}
                style={{padding: '9px 10px', verticalAlign: 'top', width: totalAfterAllCalculation ? `${numberFormat(totalAfterAllCalculation).length+2}ch` : '100px'}} onMouseOver={(e) => showConvertedValue(e, totalAfterAllCalculation)}
                onMouseOut={hideConvertedValue}>
                <span className={'input-wrap'}>
                    <span className={'width-machine'} aria-hidden={true}>{numberFormat(totalAfterAllCalculation)}</span>
                    {numberFormat(totalAfterAllCalculation)}
                    <input type="hidden" name={"items[" + index + "][total_after_deduction]"}
                           disabled={isReadOnly}
                           value={totalAfterAllCalculation}
                           className={'input'}/>
            </span>
            </td>
            <td className={"Last-Table-Cell"}>
                <div className={'buttons-wrapper'}>
                    <button type={'button'} className={"plus-icon"} onClick={() => addNewItem(index+1)} disabled={isReadOnly}>
                        <img src={plusIcon} alt="Plus icon"/>
                    </button>
                    <button style={{border: "none", background: "unset", float: "right", padding: '0'}}
                            data-itemid={state.item.id}
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                            disabled={isReadOnly}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === 'Tab') {
                                    e.preventDefault();
                                    setFocusedId(getNextFocusableField([index, 7]))
                                }
                            }
                            }
                    >
                        <img src={deleteIcon} alt="Delete icon"/>
                    </button>
                    {state.item.item_type === 2 &&
                        <label style={{margin: "0px"}}
                               data-itemid={state.item.id}
                               htmlFor={"is_taxable_for_second_tax_" + state.item.id}>
                            <input style={{float: "left", cursor: "pointer"}}
                                   id={"is_taxable_for_second_tax_" + state.item.id}
                                   title="Is spare part old"
                                   name={"items[" + index + "][is_taxable_for_second_tax]"}
                                   type="checkbox"
                                   checked={state.item.is_taxable_for_second_tax || false}
                                   onChange={(e) => {
                                       handleClicked(e)
                                   }}
                                   value={state.item.is_taxable_for_second_tax || false}
                                   disabled={isReadOnly}/>
                        </label>
                    }
                </div>
            </td>
        </tr>
    );
}


export default React.memo(FormBillItemsRow);
