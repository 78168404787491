import {AxiosResponse} from "axios";
import {
    byDocumentUrl,
    contractsUrl,
    damageBillUrl,
    damagesUrl,
    documentUrl,
    sparePartsCoverageUrl
} from "../ApiService";
import {client, headers} from "../../app/axiosClient";

/**
 * Gets a bill
 * @param billId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getBill(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function toggleLock(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.put(`${damageBillUrl}/${billId}/togglelock`, new FormData(), {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function toggleDelete(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.put(`${damageBillUrl}/${billId}/toggledelete`, new FormData(), {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function resolveBill(billId: string, isClosed: boolean, cancelToken?: any): Promise<AxiosResponse> {
    const d = new FormData();
    d.append('is_damage_closed_by_this_bill', isClosed ? 1 : 0)
    return client.put(`${damageBillUrl}/${billId}/resolvebill`, d, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function createBill(fd: FormData, cancelToken?: any): Promise<AxiosResponse> {
    return client.post(byDocumentUrl, fd, {
        headers: {
            ...headers,
        },
        timeout: 0,
        cancelToken: cancelToken
    })
}

export function getBillCount(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/linkedtobill`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function checkAuthorizedBills(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/checkauthorizedbills`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function getGivitLink(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/givitlink`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

/**
 * Gets a damage of a bill
 * @param damageId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getDamage(damageId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damagesUrl}/${damageId}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function getBillsLinkedToDamage(damageNumber: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}?filters[0][operator]=eq&filters[0][property]=damage_number&filters[0][value]=${damageNumber}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function getBillsLinkedToSchadensnummer(damageNumber: string, billNumber: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}?filters[0][operator]=eq&filters[0][property]=damage_number&filters[0][value]=${damageNumber}&filters[1][operator]=in&filters[1][property]=processing_status&filters[1][value]=5,3&version_bill_main=${billNumber}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function getBillsLinkedToVertragsvereinbarung(contractNumber: string, billNumber: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}?filters[0][property]=contract_id&filters[0][operator]=eq&filters[0][value]=${contractNumber}&filters[1][operator]=in&filters[1][property]=processing_status&filters[1][value]=5,3&version_bill_main=${billNumber}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function getBillsLinkedToFahrzeugidentnummer(vin: string,  billNumber: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}?vin=${vin}&filters[1][operator]=in&filters[1][property]=processing_status&filters[1][value]=5,3&version_bill_main=${billNumber}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

/**
 * Gets a contract
 * @param contractId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getWarrantyContract(contractId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${contractsUrl}/${contractId}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

/**
 * Gets the spent amount of a contract
 * @param contractId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getContractSpentAmount(contractId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damagesUrl}/${contractId}/spentamount`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

/**
 * Get the material and wage reimbursements of a contract
 * @param contractId
 * @param mileAge
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getWarrantyContractMaterialWageReimbursements(contractId: number, mileAge: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${contractsUrl}/${contractId}/materialwagereimbursments/${mileAge}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

/**
 * Gets a previous damage
 * @param damageId
 * @param contractId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getPreviousDamage(damageId: number, contractId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damagesUrl}/${damageId}/${contractId}/previousdamage`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

/**
 * Gets all items of a bill
 * @param billId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getBillItems(billId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/items`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

export function getSparePartsForLoadedBill(contractId,damageId, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${sparePartsCoverageUrl}/${contractId}/${damageId}`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

export function updateBill(billId: string, data: any, cancelToken?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    for (const pair of Object.entries(data)) {
        fd.append(pair[0], pair[1]);
    }

    return client.put(`${damageBillUrl}/${billId}`, fd, {
        headers: {
            ...headers,
        },
        cancelToken
    })
}

export function getBillManualSetupData(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/manuallysetupdata`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}

export function updateBillManualSetupData(billId: string, data: any, cancelToken?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    for (const pair of Object.entries(data)) {
        fd.append(pair[0], pair[1]);
    }

    return client.put(`${damageBillUrl}/${billId}/manuallysetupdata`, fd, {
        headers: {
            ...headers,
        },
        cancelToken
    })
}

export function markBillForPayment(billId: number, user_notified: number, cancelToken?: any): Promise<AxiosResponse> {
    if (user_notified)
        return client.put(`${damageBillUrl}/${billId}/prepareforpayment?user_notified=${user_notified}`, new FormData(), {
            headers: {
                ...headers,
            },
            cancelToken
        })
    else
        return client.put(`${damageBillUrl}/${billId}/prepareforpayment`, new FormData(), {
            headers: {
                ...headers,
            },
            cancelToken
        })
}

export function markBillAsDeclinedForPayment(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.put(`${damageBillUrl}/${billId}/decline`, new FormData(), {
        headers: {
            ...headers,
        },
        cancelToken
    })
}

export function markBillAsAuthorizedForPayment(billId: string, isClosed: boolean, user_notified: number, cancelToken?: any): Promise<AxiosResponse> {
    const d = new FormData();
    d.append('is_damage_closed_by_this_bill', isClosed ? 1 : 0)
    if (user_notified)
        return client.put(`${damageBillUrl}/${billId}/authorize?user_notified=${user_notified}`, d, {
            headers: {
                ...headers,
            },
            cancelToken
        });
    else
        return client.put(`${damageBillUrl}/${billId}/authorize`, d, {
            headers: {
                ...headers,
            },
            cancelToken
        });
}

export function getBillReport(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/invoicereport`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}

export function getBillDocs(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/documents?filters[0][property]=document_type&filters[0][operator]=eq&document_type&filters[0][value]=1`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}

export function getBillDocData(docHash: string, toDownload = false, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${documentUrl}/${docHash}`, {
        headers: {
            ...headers,
        },
        params: {
            download: toDownload ? 1 : 0
        },
        cancelToken: cancelToken
    })
}

export function getBillFilters(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/damagebillsfilters`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}

export function getBillLogInfo(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/logbills`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}

export function getBillCurrencyRate(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/currencyrates/${billId}`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}

export function putUpdateCurrency(billId: string, domestic_currency: string, foreign_currency?: string, rate?: string, cancelToken?: any): Promise<AxiosResponse> {
    const d = new FormData();
    d.append('damage_bill_version_id', billId);
    d.append('domestic_currency', domestic_currency);
    foreign_currency && d.append('foreign_currency', foreign_currency);
    rate && d.append('rate', rate);
        return client.put(`${damageBillUrl}/currencyrates/${billId}`, d, {
            headers: {
                ...headers,
            },
            cancelToken
        });
}

export function getBillValidationData(damage_bill_id: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${damage_bill_id}/validate-data`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}

export function getOcrFile(billId: number, cancelToken?: any): Promise<AxiosResponse<{ file: string }>> {
    return client.get(`${damageBillUrl}/getocrfiles/${billId}`, {
        headers: {
            ...headers,
        },
        cancelToken
    })
}

export function getDuplicatesInfo(billId: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/${billId}/duplicates-info`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}