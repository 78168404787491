import * as React from 'react';
import {Button} from "react-bootstrap";
import {MdSearch} from "react-icons/md";
import {BsFileEarmarkText} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import DocumentService from "../../../services/DocumentService";
import DamageInfoStatus from "./DamageInfoStatus";
import useApi from "../../../hooks/useApi";
import {getBillReport, getOcrFile} from "../../../services/API/damageBill.service";
import {useCallback, useEffect} from "react";
import BillDocumentTranslations from "../../../translations/BillDocument";
import EditAccess from "./RequestAccessButton";
import GivitDamageButton from "./GivitDamageButton";
import {BILL_PROCESSING_STATUSES} from "../../StatusContext";
import {mergeBillsRollback} from "../../../services/API/damageBills.service";
import toast, {Toaster} from "react-hot-toast";
import {AxiosError, AxiosResponse} from "axios";
import {fetchDamageBill} from "../../../features/DamageBill/bill.actions";
import {fetchBills, updateFilters} from "../../../features/SearchPagination/searchPagination.slice";


function DamageInfoCTAs({showClaimsModal, showDocumentsWindow, showPaymentLetterModal, changeIndicator, refreshLogsIndicator}) {
    const {bill, isManualSetup, allowPDFExport, isReadOnly, hadEditOption, lockedBy} = useSelector(state => state.damageBill)

    const {data, request, error} = useApi(getBillReport);
    const uid = useSelector(state => state.auth.loggedUser.uid);

    useEffect(() => {
        if (!data) return;
        if (error) {
            toast.error(error, {id: 'billReportDownloadError'});
        } else {
            if (!data.file) return;
            DocumentService.requestDownload(data.file, data.filename);
        }
    }, [data, error]);

    const getInvoiceReportForClient = () => {
        request({id: bill?.damage_bill_id});
    }
    const dispatch = useDispatch();
    const rollbackMerge = async () => {
        await toast.promise(
            mergeBillsRollback(bill?.id),
            {
                loading: 'Rollback merge', success: (response: AxiosResponse) => {
                    if (response.status !== 200) return;
                    dispatch(fetchDamageBill(bill?.damage_bill.id));
                    dispatch(updateFilters({}));
                    dispatch(fetchBills());
                    return `Bill successfully rollbacked`
                }, error: (err: AxiosError) => {
                    console.log(err)
                    return err.response?.data?.message || 'Oops'
                }
            })

    };
    const downloadOcrFile = useCallback(async (billId) => {
        try {
            const {data} = await getOcrFile(billId);

            if (data.file) {
                const type = data.file.split(',')[0].split(':')[1].split(';')[0];
                DocumentService.requestDownload(data.file.split(',')[1], `ocr_json_${billId}`, type);
                changeIndicator();
            }
        } catch (e) {
            toast.error(e?.response?.data?.message);
        }
    }, [changeIndicator])

    return (<div className={'damage-info-hole-container'}>
        <Toaster/>
        <div className={'damage-info-status-and-edit'}>
            <DamageInfoStatus refreshLogsIndicator={refreshLogsIndicator}/>
            {lockedBy!==uid && lockedBy!==null && <EditAccess/>}
            { ((bill?.processing_status === BILL_PROCESSING_STATUSES.DOCUMENT_REQUEST_MANUAL_SETUP && (bill?.bill_unlocked_for_edit_by_user_id === null || true)) || (bill?.processing_status === BILL_PROCESSING_STATUSES.BILL_IN_PROGRESS && !isReadOnly)) && bill?.merge_ids !== null && bill?.merge_ids.length !== 0
                && <Button variant="outline-primary"
                           size={'sm'}
                           style={{
                               textTransform: 'capitalize',
                           }}
                           onClick={rollbackMerge}>
                    Rollback merge
                </Button>}
        </div>
        <div className={'damage-status-buttons-container'}>
            {!isManualSetup && hadEditOption &&  <div className="damage-buttons-half">
            {(lockedBy===uid || lockedBy===null) && <EditAccess/>}
                { bill?.ocr_path && bill?.processing_status>2 && <Button variant="outline-primary"
                        size={'sm'}
                        style={{
                            textTransform: 'capitalize',
                        }} onClick={()=> downloadOcrFile(bill.damage_bill_id)}>OCR-JSON-Dateien herunterladen</Button>}

            {!isReadOnly && <Button variant="outline-primary"
                                                      size={'sm'}
                                                      style={{
                                                          textTransform: 'capitalize',
                                                      }}
                                                      onClick={showClaimsModal}>
                <MdSearch
                    style={{
                        cursor: 'pointer', width: "20.2px", height: "20.2px", objectFit: "contain",
                    }}/>
                {BillDocumentTranslations.de.claims_modal_search_claims}
            </Button>}
            </div>
            }
            <div className="damage-buttons-half">


                {bill?.processing_status===BILL_PROCESSING_STATUSES.BILL_PAYED &&  <Button variant="outline-primary"
                                                                                           size={'sm'}
                                                                                           onClick={showPaymentLetterModal}>
                    Zahlungsbrief erneut senden **
                </Button>}


                <GivitDamageButton/>

            {!isManualSetup && allowPDFExport && <Button variant="primary"
                                                         size={'sm'}
                                                         onClick={getInvoiceReportForClient}>
                <BsFileEarmarkText style={{
                    width: "20.2px", height: "20.2px", objectFit: "contain",
                }}
                />Zahlungsbrief anzeigen
            </Button>}

            <Button variant="outline-primary"
                    size={'sm'}
                    onClick={showDocumentsWindow}>
                <BsFileEarmarkText style={{
                    width: "20.2px", height: "20.2px", objectFit: "contain",
                }}/>
                Rechnung anzeigen
            </Button>
            </div>

        </div>
    </div>)
}

export default DamageInfoCTAs;
